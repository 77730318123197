 <!-- Resources -->
<div class="content mid">
    <h2>Pengenalan</h2>
    <p>Sila baca dan fahamkan.</p>

    <div class="card-container">
        <a class="card card-resources" (click)="openModalDiscordServer()">
            <svg class="material-icons" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 256 293"><path d="M226.011 0H29.99C13.459 0 0 13.458 0 30.135v197.778c0 16.677 13.458 30.135 29.989 30.135h165.888l-7.754-27.063l18.725 17.408l17.7 16.384L256 292.571V30.135C256 13.458 242.542 0 226.011 0zm-56.466 191.05s-5.266-6.291-9.655-11.85c19.164-5.413 26.478-17.408 26.478-17.408c-5.998 3.95-11.703 6.73-16.823 8.63c-7.314 3.073-14.336 5.12-21.211 6.291c-14.044 2.633-26.917 1.902-37.888-.146c-8.339-1.61-15.507-3.95-21.504-6.29c-3.365-1.317-7.022-2.926-10.68-4.974c-.438-.293-.877-.439-1.316-.732c-.292-.146-.439-.292-.585-.438c-2.633-1.463-4.096-2.487-4.096-2.487s7.022 11.703 25.6 17.261c-4.388 5.56-9.801 12.142-9.801 12.142c-32.33-1.024-44.617-22.235-44.617-22.235c0-47.104 21.065-85.285 21.065-85.285c21.065-15.799 41.106-15.36 41.106-15.36l1.463 1.756C80.75 77.53 68.608 89.088 68.608 89.088s3.218-1.755 8.63-4.242c15.653-6.876 28.088-8.777 33.208-9.216c.877-.147 1.609-.293 2.487-.293a123.776 123.776 0 0 1 29.55-.292c13.896 1.609 28.818 5.705 44.031 14.043c0 0-11.556-10.971-36.425-18.578l2.048-2.34s20.041-.44 41.106 15.36c0 0 21.066 38.18 21.066 85.284c0 0-12.435 21.211-44.764 22.235zm-68.023-68.316c-8.338 0-14.92 7.314-14.92 16.237c0 8.924 6.728 16.238 14.92 16.238c8.339 0 14.921-7.314 14.921-16.238c.147-8.923-6.582-16.237-14.92-16.237m53.394 0c-8.339 0-14.922 7.314-14.922 16.237c0 8.924 6.73 16.238 14.922 16.238c8.338 0 14.92-7.314 14.92-16.238c0-8.923-6.582-16.237-14.92-16.237" fill="#7289DA"/></svg>

            <span>Pelayan Discord Kami</span>

            <svg class="material-icons" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"/></svg>    </a>

        <a class="card card-resources" (click)="openModalRules()">
            <svg class="material-icons" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 48 48"><path fill="#42A5F5" d="M39 45H9s-3-.1-3-8h36c0 7.9-3 8-3 8z"/><path fill="#90CAF9" d="M8 3h32v34H8z"/><g fill="#1976D2"><path d="M18 15h16v2H18z"/><path d="M18 19h16v2H18z"/><path d="M18 23h16v2H18z"/><path d="M18 27h16v2H18z"/><path d="M18 31h16v2H18z"/></g><g fill="#1976D2"><path d="M14 15h2v2h-2z"/><path d="M14 19h2v2h-2z"/><path d="M14 23h2v2h-2z"/><path d="M14 27h2v2h-2z"/><path d="M14 31h2v2h-2z"/></g></svg>

            <span>Peraturan</span>

            <svg class="material-icons" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"/></svg>
        </a>

        <a class="card card-resources" target="_blank" rel="noopener" href="https://blog.angular.io/">
            <svg class="material-icons" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M13.5.67s.74 2.65.74 4.8c0 2.06-1.35 3.73-3.41 3.73-2.07 0-3.63-1.67-3.63-3.73l.03-.36C5.21 7.51 4 10.62 4 14c0 4.42 3.58 8 8 8s8-3.58 8-8C20 8.61 17.41 3.8 13.5.67zM11.71 19c-1.78 0-3.22-1.4-3.22-3.14 0-1.62 1.05-2.76 2.81-3.12 1.77-.36 3.6-1.21 4.62-2.58.39 1.29.59 2.65.59 4.04 0 2.65-2.15 4.8-4.8 4.8z"/></svg>

            <span>Api api</span>

            <svg class="material-icons" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"/></svg>
        </a>
    </div>
</div>
