import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'modal-rules',
  template: `
    <div class="modal-header">
      <h4 class="modal-title">Peraturan!</h4>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>1. Jangan jadi ayam derhaka.</p>
      <p>2. Hormat ayam-ayam yg lain.</p>
      <p>3. Ayam selamanya.</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Okay</button>
    </div>
  `
})
export class modalRules {
  @Input() name;

  constructor(public activeModal: NgbActiveModal) {}
}

@Component({
  selector: 'modal-discord-server',
  template: `
    <div class="modal-header">
      <h4 class="modal-title">Pengumuman Perkhidmatan Awam!</h4>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>Di dalam pembinaan. Terima Kasih untuk kesabaran anda.</p>
      
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Okay</button>
    </div>
  `
})
export class modalDiscordServer {
  @Input() name;

  constructor(public activeModal: NgbActiveModal) {}
}

@Component({
  selector: 'app-middle',
  templateUrl: './middle.component.html',
  styleUrls: []
})
export class MiddleComponent implements OnInit {

  constructor(private modalService: NgbModal) {}

  openModalRules() {
    const modalRef = this.modalService.open(modalRules);
    modalRef.componentInstance.name = 'World';
  }

  openModalDiscordServer() {
    const modalRef = this.modalService.open(modalDiscordServer);
    modalRef.componentInstance.name = 'World';
  }

  ngOnInit(): void {
  }

}
