import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: []
})
export class FormComponent implements OnInit {

  public value = "";
  public users = [{
    name: 'acap'
  },{
    name: 'ghostbyte'
  },{
    name: 'test'
  }]
  item1:number = 0;

  toggleAge = "Hide";
  toggleJob = "Hide";

  public isJobVisible = true;
  public isAgeVisible = true;

  constructor(private fb: FormBuilder) { }

  userForm = this.fb.group({
    name: ['', Validators.required],
    age: ['', Validators.required],
    job: ['', Validators.required],
  });

  ngOnInit(): void {
  }

  toggleVisibility = (control) => {
    switch(control){
      case "job" : this.isJobVisible = !this.isJobVisible; this.toggleJob = this.isJobVisible ? "Hide" : "Show"; break;
      case "age" : this.isAgeVisible = !this.isAgeVisible; this.toggleAge = this.isAgeVisible ? "Hide" : "Show"; break;
      default : break;
    }
  }

  checkValue = () => {
    if(this.userForm.status == "VALID"){
      alert("Valid");
    } else {
      alert("Invalid");
    }
  }

  addValue = (control) => {
    switch(control){
      case "item1" : this.item1++; break;
      default : break; 
    }
  }

  subtractValue = (control) => {
    switch(control){
      case "item1" : if(this.item1 >= 0) this.item1--; break;
      default : break;
    }
  }
}
