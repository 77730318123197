import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-streamer',
  templateUrl: './streamer.component.html',
  styleUrls: []
})
export class StreamerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
