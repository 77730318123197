<!-- Toolbar -->
<div class="toolbar" role="banner">
    <img 
      width="50"
      height="50"
      alt="Angular Logo"
      src="../assets/image/aymjnd.png"
    />
    <span>Selamat Datang {{userName}}</span>

    <div class="card-container top">
      <a class="card card-light">
        <svg class="material-icons" xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 24 24"><g fill="none"><path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2S2 6.477 2 12s4.477 10 10 10zm0-2V4a8 8 0 1 1 0 16z" fill="#626262"/></g></svg>
      </a>
    </div>
    
</div>
  
  