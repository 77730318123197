<div class="content">
    <p>
      Form Status: {{userForm.status}}
    </p>

    <div *ngFor="let usr of users">
        <h3>
            {{usr.name}}
        </h3>
    </div>

    <div class="card-container">
        <form [formGroup]="userForm">
            <a class="card card-form">
                <span>
                    <button style="margin-left: 5px;" (click)="toggleVisibility('age')">{{toggleAge}} Age</button>
                    <button style="margin-left: 5px;" (click)="toggleVisibility('job')">{{toggleJob}} Job</button>
                </span>
                <label>
                    Name : <input type="text" formControlName="name">
                </label>
                <label *ngIf="isAgeVisible">
                    Age : <input type="text" formControlName="age">
                </label>
                <label *ngIf="isJobVisible">
                    Job : <input type="text" formControlName="job">
                </label>
                <span>
                    <button style="margin-left: 5px;" (click)="checkValue()">Submit</button>
                </span>
            </a>
        </form>
    </div>
</div>
