<div class="content mid">
    <h2>Streamer Langsung Kami</h2>
    <p>Sokongan anda amatlah dihargai</p>

    <div class="card-container">
        <a class="card card-streamer" target="_blank" rel="noopener" href="https://www.twitch.tv/anazhd">
            <span>
                <svg class="material-icons" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M11.64 5.93h1.43v4.28h-1.43m3.93-4.28H17v4.28h-1.43M7 2L3.43 5.57v12.86h4.28V22l3.58-3.57h2.85L20.57 12V2m-1.43 9.29l-2.85 2.85h-2.86l-2.5 2.5v-2.5H7.71V3.43h11.43z" fill="#6441a5"/></svg>
                anazhd
            </span>
        
            <div>
                <img
                    width="100"
                    height="100"
                    alt="Angular Logo"
                    src="../assets/image/anazhd.png"
                />
            </div>

            <p>Lelaki yang normal.</p>

        </a>

        <a class="card card-streamer" target="_blank" rel="noopener" href="https://www.facebook.com/aznfshl/live_videos">
            <span>
                <svg class="material-icons" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2.04c-5.5 0-10 4.49-10 10.02c0 5 3.66 9.15 8.44 9.9v-7H7.9v-2.9h2.54V9.85c0-2.51 1.49-3.89 3.78-3.89c1.09 0 2.23.19 2.23.19v2.47h-1.26c-1.24 0-1.63.77-1.63 1.56v1.88h2.78l-.45 2.9h-2.33v7a10 10 0 0 0 8.44-9.9c0-5.53-4.5-10.02-10-10.02z" fill="#3b5998"/></svg>
                Eraseth
            </span>
            
            <div>
                <img
                    width="100"
                    height="100"
                    alt="Angular Logo"
                    src="../assets/image/eraseth.png"
                />
            </div>

            <p>Ninja yang terpadam.</p>

        </a>

        <a class="card card-streamer" target="_blank" rel="noopener" href="https://www.facebook.com/beyondexceed/live_videos">
            <span>
                <svg class="material-icons" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2.04c-5.5 0-10 4.49-10 10.02c0 5 3.66 9.15 8.44 9.9v-7H7.9v-2.9h2.54V9.85c0-2.51 1.49-3.89 3.78-3.89c1.09 0 2.23.19 2.23.19v2.47h-1.26c-1.24 0-1.63.77-1.63 1.56v1.88h2.78l-.45 2.9h-2.33v7a10 10 0 0 0 8.44-9.9c0-5.53-4.5-10.02-10-10.02z" fill="#3b5998"/></svg>
                Beyond Exceed
            </span>
            
            <div>
                <img
                    width="100"
                    height="100"
                    alt="Angular Logo"
                    src="../assets/image/exceed.png"
                />
            </div>
            
            <p>Sang serigala.</p>

        </a>
    </div>
</div>
